import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';


import image from '../../../assets/image/project-management/l2-content-img-1.gif'


export default function ContentSectionOne() {

  return (
    <Content>
      {/* <Content.SectionShape>
        <Img src="../../../assets/image/project-management/l2-content-1-shape.png" alt="content" layout="fullWidth" placeholder="blurred" />
      </Content.SectionShape> */}
      <Container>
        <Content.Inner>
          <Row className="align-items-center justify-content-center justify-content-lg-start">
            <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
              <Content.TextBlock>
                <Content.Title as="h2"> <SuperTag value="Bring your <br className='d-none d-lg-block' /> idea into 
               
                <br className='d-none d-lg-block' />
                 "

                />
                  <div style={{ color: "#8a55df" }} >
                    <ReactTypingEffect
                      text={["finger tips.", "finger tips."]}
                      className="highlight-text d-lg-block "
                      speed="150"
                      eraseSpeed="100"
                      typingDelay="400"
                      eraseDelay="800"
                      cursorClassName="typed-cursor"
                    />
                  </div>



                </Content.Title>

                <Content.Text> <SuperTag value="You have the freedom to imagine and generate ideas. Discuss them freely with our tech experts and make it a reality. Experience the idea with your digital screen." /> </Content.Text>
                <Content.Button to="contact">Get Started Now
                </Content.Button>
              </Content.TextBlock>
            </Col>
            <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
              <Content.Image>
                <img className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
                {/* <Content.ContentShape>
                  <Img className="w-100" src="../../../assets/image/project-management/l2-content-shape-2.png" alt="content" layout="fullWidth" placeholder="blurred" />
                </Content.ContentShape> */}
              </Content.Image>
            </Col>
          </Row>
        </Content.Inner>
      </Container>
    </Content>
  )
}